/* New Banner CSS */
/* CSS for the banner */
.slider {
    width: 50%;
    height: 200px;
    margin: 30px auto 0;
    /* Set top margin to create space from the top */
}

.slide {
    margin: 10px 0;
}

.slide img {
    max-width: 100%;
    max-height: 70vh;
    margin: 0 auto;
    object-fit: contain;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .slider {
        width: 40%;
        height: 160px;
        margin: 20px auto 0;
        /* Adjust top margin for smaller screens */
    }
}

@media (max-width: 576px) {
    .slider {
        width: 30%;
        height: 120px;
        margin: 15px auto 0;
        /* Further adjust top margin for even smaller screens */
    }
}